import { Children, useEffect, useState } from "react";
import { apiPost } from "../../../data/apidata";
import AQDataTable from "../../../components/AQDataTable/AQDataTable";
import { processCallsList } from "../../../components/charts/JourneyFunctions";
import { useSnackbar } from "notistack";

const TableTemplateAPI = ({ title, apiPath, apiParams, tableTemplate, tableSettingsType, grouping, onUpdateRefreshPath, children, siteID }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [masterData, setMasterData] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [searchVal, setSearchVal] = useState("");


    function getData() {
        if ((apiParams == undefined) || (apiParams == null) || (apiParams == "")) return;
        if (apiParams == undefined) return;
        apiPost(
            apiPath,
            apiParams,
            (d) => {
                let r, rr;

                try { r = d["message"]["data"][0]; }
                catch (error) {
                    console.error("Error accessing properties of 'd':", error);
                    r = undefined; // You can assign a default value or handle it as per your requirement
                }

                try { rr = d["message"]; }
                catch (error) {
                    console.error("Error accessing properties of 'd':", error);
                    rr = undefined; // You can assign a default value or handle it as per your requirement
                }


                // let r = d["message"]["data"][0];
                console.log('checkData', r)

                // if(tableSettingsType.includes("team_management")){
                //     console.log('userTeam')
                //     r=r[0];
                //     r.map(item=>{
                //         //item.sites=[]
                //         item.siteNames='';
                //         item.selectedSite='';
                //         if(item!==undefined){
                //         //console.log(item)
                //         apiPost(
                //             "/api/messages/site/users/usersiteslist",
                //             {'email':item.email},
                //             (res)=>{
                //                 const sites=res["message"]["data"][0][0]

                //                 const num = sites.length;
                //                 if(num>0){ 
                //             if(sites[0].siteName === undefined)
                //                 item.selectedSite = '';
                //             else
                //             item.selectedSite = sites[0].siteName;
                //             //console.log(item.selectedSiteID)
                //             console.log(item.email+" "+item.selectedSite)
                //             //console.log(sites)
                //             sites.map((site)=> {
                //                 //console.log(site.selected_site)
                //                 if(site.siteID===item.selectedSiteID)
                //                     {item.selectedSite=site.siteName;}

                //             })
                //         }
                //             },
                //             (err)=>{console.log(err)}
                //         )
                //         }
                //     })
                // }

                if (tableSettingsType.includes('settings_integrations')) {
                    const tempData = rr.map(item => {

                        let transformedStatus;

                        // Conditional logic based on item.status
                        if (item.integrationEnabled === 1) {
                            transformedStatus = 'Active';
                        } else if (item.integrationEnabled === 0) {
                            transformedStatus = 'Inactive';
                        } else {
                            transformedStatus = 'Unknown';
                        }

                        return {
                            integrationEnabled: transformedStatus,
                            integrationKey: item.integrationKey,
                            integrationType: item.integrationType,
                            siteID: item.siteID
                        }
                    });
                    setMasterData(tempData)
                }

                if (tableSettingsType.includes("team_management")) {
                    console.log(d["message"]["data"][0][0]);
                    r = d["message"]["data"][0][0]
                    r.map(row => {

                        row.siteNames = '';
                        row.selectedSite = '';
                        if (row.sites.length > 0) {
                            const num = row.sites.length;
                            row.selectedSite = row.sites[0].siteName;
                            row.siteNames = '';
                            row.sites.map((site, idx) => {
                                if (site.siteID === row.selectedSiteID)
                                    row.selectedSite = site.siteName;
                                row.siteNames += site.siteName + " ";

                                if (idx !== num - 1)
                                    row.siteNames += '- '
                            }
                            )
                        }
                    }
                    )
                };

                if (tableSettingsType.includes('user_management')) {
                    //console.log(r[0].sites)
                    r.map(row => {

                        row.siteNames = '';
                        row.selectedSite = '';
                        if (row.sites.length > 0) {
                            const num = row.sites.length;
                            row.selectedSite = row.sites[0].siteName;
                            row.siteNames = '';
                            row.sites.map((site, idx) => {
                                if (site.siteID === row.selectedSiteID)
                                    row.selectedSite = site.siteName;
                                row.siteNames += site.siteName + " ";

                                if (idx !== num - 1)
                                    row.siteNames += '- '
                            }
                            )
                        }
                    }
                    )
                };
                if ((grouping) && (grouping === "journey")) {
                    // const tmp = Object.values(r).map(item => { if (item.SenseType === 'Emergency') return item })
                    // console.log('console', tmp)
                    r = processCallsList(r);
                    console.log("loggg", r)
                }
                if (r == null) r = [];

                if (r.length != 0) { setMasterData(r); }

            },
            (e) => {
                enqueueSnackbar(e, { variant: "error" });
                console.log(e)
            }
        );
    }

    function updateSearchVal(e) {
        setSearchVal(e.target.value);
    }

    useEffect(() => {
        if (searchVal === "") setData(masterData);
        if (masterData === null) return;
        if (searchVal === "") setData(masterData);
        const tmp = masterData.filter(obj => (obj.unitId.includes(searchVal)) || (obj.unitName.includes(searchVal)) || (obj.unitZone.includes(searchVal)) || ((obj.groupid) ? (obj.groupid.includes(searchVal)) : ""));
        setData(tmp);
    }, [searchVal]);

    useEffect(() => {
        getData()
    }, [apiPath, apiParams]);
    useEffect(() => setData(masterData), [masterData]);




    return (
        <>
            <div className="flex-column form_container">
                <h4>{title}</h4>

                <div>{children}</div>
                <div className="usermanagement_user_roles_menu_container">

                    <div className="usermanagement_user_roles_menu">
                    </div>
                </div>

                <div className="usermanagement_list_container">
                    {
                        (data) === null ?
                            <div>Retrieving data...</div>
                            :
                            <AQDataTable
                                siteID={siteID}
                                data={Object.values(data)}
                                columns={tableTemplate}
                                settingsType={tableSettingsType}
                                onUpdateRefreshPath={onUpdateRefreshPath}
                                dataRefresh={getData}
                            />
                    }
                </div>
            </div>
        </>

    );
}


export default TableTemplateAPI;