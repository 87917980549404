import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";

const BarChartKPI = ({data}) => {

  const [dataBreakdown, setDataBreakdown] = useState([])
  
  useEffect(()=>{
    console.log("updateddata", data);
    setDataBreakdown(data)
  }, [data])

  function showBreakdown(title, bData) {
    setDataBreakdown(bData);
  }

  function formatSecsAsMins(v) {
    console.log("formatSecsAsMins", v);
    const date = new Date(0); // Start at the Unix epoch (1970-01-01T00:00:00Z)
    date.setSeconds(v);
  
    const minutes = String(Math.floor(v / 60)).padStart(2, '0');
    const seconds = String(v % 60).padStart(2, '0');
    let secString = seconds.split(".")[0];
    return `${minutes}:${secString}`;
  }

/*
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    legend: { display: false },
    tooltips: {
      backgroundColor: '#4cc1bd',
    },    
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    responsive: true,
    scale: {
      y: {
        ticks: {
          callback: function(value, index, values) {
            return "test";
            return formatSecsAsMins(value);
          },
        },
      },
      xAxes: [{
        barPercentage: 0.5,
        scaleLabel: { display: true, labelString: "Date" },
        gridLines: { display: true, drawOnChartArea: false }
      }],
      yAxes: [{
        ticks:{
          callback: function(value, index, values) {
            return "testsss";
            return formatSecsAsMins(value);
          },
          //callback:(v)=>formatSecsAsMins(v),
          stepSize:30, //add a tick every 5 minutes
        },
        scaleLabel: { display: true, labelString: "Average Time (mins)" },  
        gridLines: { display:true, drawOnChartArea: true }
      }],
    },
      autocolors: false,
      
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: 0,
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 2,
            label: {
              enabled: false,
              content: 'Test label'
            }
  
          },
          
        ]
      } 
           
  }
  */

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        backgroundColor: '#4cc1bd',
        callbacks: {
          label: function(tooltipItem) {
            //return tooltipItem.value;
            return formatSecsAsMins(tooltipItem.raw); // Adjust formatting as needed
          }
        }
      },
      autocolors: false,
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: 0,
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 2,
            label: {
              enabled: false,
              content: 'Test label'
            }
          }
        ]
      }
    },
    scales: {
      x: {
        barPercentage: 0.5,
        scaleLabel: { 
          display: true, 
          labelString: "Date" 
        },
        grid: { 
          display: true, 
          drawOnChartArea: false 
        }
      },
      y: {
        ticks: {
          callback: function(value, index, values) {
            return formatSecsAsMins(value);
          },
          stepSize: 30, // add a tick every 30 units
        },
        scaleLabel: { 
          display: true, 
          labelString: "Average Time (mins)" 
        },
        grid: { 
          display: true, 
          drawOnChartArea: true 
        }
      }
    }
  };

  return ( <BarChart datasets={dataBreakdown} options={options}/> );

}


export default BarChartKPI;
