import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormInput } from '../../../components/Input';
import { apiPost } from '../../../data/apidata';
import DELETE_ICON from '../../../images/settings/delete.png';

const NewGroups=({onClose, siteID})=>{

    const { enqueueSnackbar } = useSnackbar();
    const [GroupID, setGroupID] = useState()
    const [GroupName, setGroupName] = useState([])
    const [error, setError] = useState("");
    const [unitSelection, setUnitSelection] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState([]);
    const [filter, setFilter] = useState(true);
    const [GroupAdded, setGroupAdded] = useState(false);

    const handleChange=(e)=>{
        const { value } = e.target;
        setGroupName(value);
        console.log('groupName',GroupName)
    }

    const handleGroupSubmit=(e)=>{
        e.preventDefault();
        console.log('submitName', GroupName)
        if(GroupName===undefined || null){
            setError("You have not provide a group name.");
        }
        else{
            apiPost(
                'api/messages/site/devicegroups/add',
                {
                    'siteID': siteID,
                    'groupName': GroupName
                },
                (d)=>{
                    console.log('groupAdd_success',d.message[0].ID)
                    setGroupID(d.message[0].ID)
                    setGroupAdded(true)
                    enqueueSnackbar("Group created", {variant:"success"});
                    },
                (e)=>{console.log('groupAdd_err',e)}
            )
        }
    }

    useEffect(()=>{
        apiPost(
            'api/messages/site/devicegroups/group/devices',
            {
                'siteID' : siteID,
                'deviceGroupID': GroupID
            },
            (d)=>{
                console.log('assignUnits', d.message)
                const unit = d.message;
                setSelectedUnit(unit)
            },
            (e)=>{console.log(e)}
        )
    },[GroupAdded])

    useEffect(()=>{
        apiPost(
            'api/messages/site/devicegroups/devices/list',
            {
                'siteID' : siteID,
            },
            (d)=>{
                console.log('device_list:', d.message[0])
                setFilter(true)
                setUnitSelection(d.message[0])

            },
            (e)=>{console.log(e)}
        )
    },[selectedUnit])

    useEffect(()=>{
        console.log('filter', unitSelection)
        if (filter){
            const unitToRemove = new Set(unitSelection.filter(item => item.status === 'Unavailable').map(item => item.status));
            const tmp = unitSelection.filter(item => !unitToRemove.has(item.status));
            setFilter(false);
            setUnitSelection(tmp);
        }
    },[unitSelection, selectedUnit])

    function refreshSelections (){
        apiPost(
            'api/messages/site/devicegroups/group/devices',
            {
                'siteID' : siteID,
                'deviceGroupID': GroupID
            },
            (d)=>{
                console.log('checkAssign', d.message)
                const unit = d.message;
                setSelectedUnit(unit)
            },
            (e)=>{console.log(e)}
        )
    }

    function FFilter(){
        if (filter){
            const unitToRemove = new Set(unitSelection.filter(item => item.status === 'Unavailable').map(item => item.status));
            const tmp = unitSelection.filter(item => !unitToRemove.has(item.status));
            console.log('checkFilter', tmp)
            setFilter(false);
            setUnitSelection(tmp);
        }
    }

    function addUnit(deviceCode ){
        console.log('deviceAdd', deviceCode, GroupID)
        apiPost(
            'api/messages/site/devicegroups/group',
            {
                'deviceCode' : deviceCode,
                'deviceGroupID' : GroupID
            },
            (d)=>{console.log('deviceAdd_success', d)
                setFilter(true)
                refreshSelections()
            },
            (e)=>{console.log(e)}
        )
    }

    function removeUnit(deviceCode){
        console.log('remove', deviceCode)
        apiPost(
            'api/messages/site/devicegroups/ungroup',
            {
                'deviceCode' : deviceCode,
                'deviceGroupID' : GroupID
            },
            (d)=>{
                console.log('ungroup', deviceCode)
                const tmp = selectedUnit.filter(item=>item.dv_deviceCode !== deviceCode);
                setFilter(true)
                refreshSelections()

            },
            (e)=>{console.log('ungroup', e)}
        )
    }

    function clearAll(){
        try{selectedUnit.map(item=>removeUnit(item.deviceCode))}
        catch(e){console.log(e)}
    }



    return (
        <div className='inner_layer' style={{height:'450px', width: '500px'}}>
            <h1>Create Group</h1>
            {GroupAdded ?
                (
                <div className='aqForm' style={{height: '400px'}}>
                    <div className='aq_fields' style={{fontSize:'16px'}}>
                        <h4 style={{marginTop:0}}>Step 2/2: Fill in group details</h4>
                        {/* {(error!=="")&&<><small className='formFieldError'>{error}</small><br /></>} */}
                        <div style={{color: '#6B7280'}}>
                            Assigned Units:
                            <div className='flex-row'>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <div style={{marginBottom:'5px'}}>
                                        {console.log('checkA', selectedUnit)}
                                        {selectedUnit.map((d,i)=>(
                                            d !=null?
                                            <div className='tag-item'>
                                                <span className='text'>{d.deviceName}({d.deviceCode})</span>
                                                <span onClick={()=>removeUnit(d.deviceCode)} className='close'>&#10006;</span>
                                            </div>
                                            :null
                                        ))}
                                    </div>
                                        {
                                            selectedUnit.length>1?
                                            <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                                <button className='clear_button' onClick={clearAll}>Clear <img src={DELETE_ICON}/></button>
                                            </div>
                                            :null
                                        }
                                    <div>
                                        <input 
                                            className='tag_input'
                                            type='text'
                                            id='autocomplete-input'
                                            list='suggestions'
                                            placeholder='Select a device to add...'
                                            onChange={(e)=> {
                                                {console.log('checkB', unitSelection)}
                                                const selectedOption = unitSelection.find(opt=>opt.dv_deviceName === e.target.value)
                                                if (selectedOption) {
                                                    console.log('select', selectedOption.dv_deviceCode)
                                                    addUnit(selectedOption.dv_deviceCode, selectedOption.dv_deviceName)
                                                    e.target.value = ''
                                                }
                                            }}>
                                        </input>
                                        <datalist id='suggestions'>
                                            {FFilter()}
                                            {console.log('checkUS',unitSelection)}
                                            {unitSelection.map(o=><option key={o.dv_deviceCode} value={o.dv_deviceName}>{o.dv_deviceName} ({o.dv_deviceCode})</option>)}
                                        </datalist>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='submit_fields'>
                            <div className='validate_close_div'>
                                <button className='close_buton' onClick={
                                    ()=>{
                                        setGroupAdded(false)
                                        enqueueSnackbar("Group created", {variant:"success"});
                                        onClose()
                                    }
                                }>Close</button>
                            </div>
                        </div>
                    </div>
                </div>)
                :
                (<div className='aqForm' style={{height: '400px'}}>
                    <div className='aq_fields' style={{fontSize:'16px'}}>
                        <h4 style={{marginTop:0}}>Step 1/2: Fill in group details</h4>
                        {(error!=="")&&<><small className='formFieldError'>{error}</small><br /></>}
                        <label style={{color: '#6B7280'}}>
                            Group Name :
                            <FormInput
                                type='text'
                                name='groupName'
                                value={GroupName}
                                onChange={handleChange}/>
                        </label>
                        <div className='submit_fields'>
                            <div className='validate_close_div'>
                                <button className='validate_button' onClick={handleGroupSubmit} style={{width: '70px'}}>Create</button>
                                <button className='close_buton' onClick={onClose} style={{width: '70px'}}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </div>
    );
};

export default NewGroups;
