import { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { PieItemIdentifier, DefaultizedPieValueType } from '@mui/x-charts/models';
import { Typography, Stack } from '@mui/material';
import { groupBy } from '../../functions/objects_arrays';

const formatObject = (obj) => {
  if (obj === null) {
    return '  undefined';
  }
  return JSON.stringify(obj, null, 2)
    .split('\n')
    .map((l) => `  ${l}`)
    .join('\n');
};
const PieChartCustom=({data})=> {

  const [identifier, setIdentifier] = useState(null);
  const [id, setId] = useState(undefined);

  const handleClick = (
    event,
    itemIdentifier,
    item,
  ) => {
    setId(item.id);
    setIdentifier(itemIdentifier);
  };


  return (
    <div>
    {
    ((data)&&(data!==null))
    &&
      <PieChart
        series={[
          {
            data: data,
          },
        ]}
        //onClick={handleClick}
        width={400}
        height={200}
        margin={{ right: 200 }}
      />
    }
    </div>
  );

}


export default PieChartCustom;  