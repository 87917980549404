import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormInput } from '../../../components/Input';
import { apiPost } from '../../../data/apidata';
import DELETE_ICON from '../../../images/settings/delete.png';

const EditGroup = ({onClose, data, siteID}) => {

    const OldGroupName = data.devicegroupName
    console.log('checkName',OldGroupName)
    const { enqueueSnackbar } = useSnackbar();
    const [GroupID, setGroupID] = useState(data.deviceGroupID)
    const [GroupName, setGroupName] = useState(data.devicegroupName)
    const [unitSelection, setUnitSelection] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState([data.groupDevices]);
    const [filter, setFilter] = useState(true);
    const [error, setError] = useState("");


    const handleChange=(e)=>{
        const { value } = e.target;
        setGroupName(value);
        console.log('groupName',GroupName)
    }

    const handleGroupSubmit=(e)=>{
        e.preventDefault();
        console.log('submitName', GroupName)
        if(GroupName===undefined || null){
            setError("You have not provide a group name.");
        }
        else if(OldGroupName === GroupName)
        {
            console.log('checkSubmit','nothing happened')
            onClose()
        }
        else{
            apiPost(
                'api/messages/site/devicegroups/edit',
                {
                    'groupID': GroupID,
                    'oldGroupName': OldGroupName,
                    'groupName': GroupName
                },
                (d)=>{
                    console.log('groupAdd_success',d.message)
                    setGroupID(d.message)
                    enqueueSnackbar("Group created", {variant:"success"});
                    onClose()
                    },
                (e)=>{console.log('groupAdd_err',e)}
            )
        }
    }

    useEffect(()=>{
        refreshSelections()
    },[])

    useEffect(()=>{
        apiPost(
            'api/messages/site/devicegroups/devices/list',
            {
                'siteID' : siteID,
            },
            (d)=>{
                console.log('device_list:', d.message[0])
                setFilter(true)
                setUnitSelection(d.message[0])

            },
            (e)=>{console.log(e)}
        )
    },[selectedUnit])

    useEffect(()=>{
        console.log('filter', unitSelection)
        if (filter){
            const unitToRemove = new Set(unitSelection.filter(item => item.status === 'Unavailable').map(item => item.status));
            const tmp = unitSelection.filter(item => !unitToRemove.has(item.status));
            console.log('checkFilter', tmp)
            setFilter(false);
            setUnitSelection(tmp);
        }
    },[filter, selectedUnit])

    function FFilter(){
        if (filter){
            const unitToRemove = new Set(unitSelection.filter(item => item.status === 'Unavailable').map(item => item.status));
            const tmp = unitSelection.filter(item => !unitToRemove.has(item.status));
            console.log('checkFilter', tmp)
            setFilter(false);
            setUnitSelection(tmp);
        }
    }

    function refreshSelections (){
        apiPost(
            'api/messages/site/devicegroups/group/devices',
            {
                'siteID' : siteID,
                'deviceGroupID': GroupID
            },
            (d)=>{
                console.log('checkAssign', d.message)
                const unit = d.message;
                setSelectedUnit(unit)
            },
            (e)=>{console.log(e)}
        )
    }

    function FFilter(){
        if (filter){
            const unitToRemove = new Set(unitSelection.filter(item => item.status === 'Unavailable').map(item => item.status));
            const tmp = unitSelection.filter(item => !unitToRemove.has(item.status));
            console.log('checkFilter', tmp)
            setFilter(false);
            setUnitSelection(tmp);
        }
    }


    function addUnit(deviceCode){
        console.log('deviceAdd', deviceCode, GroupID)
        apiPost(
            'api/messages/site/devicegroups/group',
            {
                'deviceCode' : deviceCode,
                'deviceGroupID' : GroupID
            },
            (d)=>{
                console.log('deviceAdd_success', d)
                setFilter(true)
                refreshSelections()
            },
            (e)=>{console.log(e)}
        )
    }

    function removeUnit(deviceCode){
        apiPost(
            'api/messages/site/devicegroups/ungroup',
            {
                'deviceCode' : deviceCode,
                'deviceGroupID' : GroupID
            },
            (d)=>{
                console.log('ungroup', deviceCode)
                setFilter(true)
                refreshSelections()

            },
            (e)=>{console.log('ungroup', e)}
        )
    }

    function clearAll(){
        console.log('checkCLEAR', selectedUnit)
        try{selectedUnit.map(item=>removeUnit(item.deviceCode))}
        catch(e){console.log(e)}
    }
    
    return(
        <div className='inner_layer' style={{height:'450px', width: '500px'}}>
            <h1>Edit Group</h1>
            <div className='aqForm' style={{height: '400px'}}>
                <div className='aq_fields' style={{fontSize:'16px'}}>
                    {(error!=="")&&<><small className='formFieldError'>{error}</small><br /></>}
                    <label style={{color: '#6B7280'}}>
                        Group Name :
                        <FormInput
                            type='text'
                            name='groupName'
                            value={GroupName}
                            onChange={handleChange}/>
                    </label>
                    <div style={{color: '#6B7280'}}>
                        Assigned Units:
                        <div className='flex-row'>
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <div style={{marginBottom:'5px'}}>
                                    {console.log('checkA', selectedUnit)}
                                    {selectedUnit.map((d,i)=>(
                                        d !=null?
                                        <div className='tag-item'>
                                            <span className='text'>{d.deviceName}({d.deviceCode})</span>
                                            <span onClick={()=>removeUnit(d.deviceCode)} className='close'>&#10006;</span>
                                        </div>
                                        :null
                                    ))}
                                </div>
                                <div>
                                    {
                                        selectedUnit.length>1?
                                        <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                                            <button className='clear_button' onClick={clearAll}>Clear <img src={DELETE_ICON}/></button>
                                        </div>
                                        :null
                                    }
                                </div>
                                <div>
                                    <input 
                                        className='tag_input'
                                        type='text'
                                        id='autocomplete-input'
                                        list='suggestions'
                                        placeholder='Select a device to add...'
                                        onChange={(e)=> {
                                            {console.log('checkB', unitSelection)}
                                            const selectedOption = unitSelection.find(opt=>opt.dv_deviceName === e.target.value)
                                            if (selectedOption) {
                                                console.log('checkSelect', selectedOption.dv_deviceCode)
                                                addUnit(selectedOption.dv_deviceCode, selectedOption.dv_deviceName)
                                                e.target.value = ''
                                            }
                                        }}>
                                    </input>
                                    <datalist id='suggestions'>
                                        {FFilter()}
                                        {console.log('checkUS',unitSelection)}
                                        {unitSelection.map(o=><option key={o.dv_deviceCode} value={o.dv_deviceName}>{o.dv_deviceName} ({o.dv_deviceCode})</option>)}
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='submit_fields'>
                        <div className='validate_close_div'>
                            <button className='validate_button' onClick={handleGroupSubmit} style={{width: '70px'}}>Save</button>
                            <button className='close_buton' onClick={onClose} style={{width: '70px'}}>Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )





};

export default EditGroup;