import './careclicksfield.css';
import IMG_PCARE from '../../images/care_clicks/personal_care.png';
import IMG_CONT from '../../images/care_clicks/continence.png';
import IMG_NUT from '../../images/care_clicks/nutrition_hydration.png';
import IMG_SLP from '../../images/care_clicks/sleep_rest.png';
import IMG_SOC from '../../images/care_clicks/social.png';
import IMG_MOV from '../../images/care_clicks/moving.png';
import IMG_TOGGLE_NEG from '../../images/care_clicks/toggle_negative.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

const CareClicksField = (props) => {

    const GetDisplayIcon=({arr})=>{
        //remove duplicates from arr
        arr = arr.filter(function(item, pos) {
            return arr.indexOf(item) == pos;
        })
        return arr.map(a=><GetRTNVal value={a} />);
    }

    function processClicks(careClicks) {
        if ((typeof(careClicks) === "object")&&(Array.isArray(careClicks)===false)){
            //console.log("CareClicksField: processClicks: careClicks is an object", Object.values(careClicks));
            return Object.values(careClicks);
        }
        careClicks = careClicks.filter(c=>c!==null);
        return careClicks;
    }

    const GetRTNVal=({value})=>{
        
        if (value==="personal") return <BuildCareIcon tooltip="Personal Care" alt="Personal Care" src={IMG_PCARE} />;
        if (value==="continence") return <BuildCareIcon tooltip="Continence" alt="Continence" src={IMG_CONT} />;
        if (value==="nutandhyd") return <BuildCareIcon tooltip="Nutrition and Hydration" alt="Nutrition and Hydration" src={IMG_NUT} />;
        if (value==="sleep") return <BuildCareIcon tooltip="Sleep" alt="Sleep" src={IMG_SLP} />;
        if (value==="social") return <BuildCareIcon tooltip="Social" alt="Social" src={IMG_SOC} />;
        if (value==="moving") return <BuildCareIcon tooltip="Moving" alt="Moving" src={IMG_MOV} />;
        
        if ((value===undefined)||(value===null)||(value==="")) return <></>;

        return <CareComment 
            tooltip="Care Comment" 
            alt="Care Comment" 
            src={IMG_TOGGLE_NEG} 
            comment={value} />;
                
    }

    if(!props.careClicks) return null;
    const careClicks = processClicks(props.careClicks);


    return(
        <div style={{display:"flex", flexDirection: "row", flexWrap: "wrap"}}>
            {
            //Object.keys(props.careClicks).map((c,i)=>{
            careClicks.map((c,i)=>{
                if (c===undefined) return <></>;
                let cVal = c;
                if ((typeof(cVal)==="object")&&(Object.keys(cVal).length===1)) cVal = Object.values(c)[0];
                //if (Object.keys(c).length===1) return <></>;
                let val = (cVal.CallType==="Comments")? cVal.CallValue : cVal.CallType;
                return <GetRTNVal value={val} key={i} />
            })
            }
        </div>
    );
}


const BuildCareIcon = (props) => {
    return (
        <div>
          <Tooltip title={<div className="tooltipStyle">{props.tooltip}</div>}>
            <img className="care_selected" alt={props.tooltip} src={props.src} />
          </Tooltip>
        </div>
    );
}

const CareComment = (props) => {
    let comment = props;

    return (
    <Tooltip title={<div className="tooltipStyle">{props.comment}</div>}>
        <div className="dashboard_careclick_comment_icon">i</div>
    </Tooltip>
    );

}

export default CareClicksField;
