
import DashboardCard, { AttendanceCard, StandardCard } from "../../components/Card";
import { Grid,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels } from "@tremor/react";
import ChartCard from "../../components/ChartCart";
import { useState } from "react";
import "./Insights.css";
import PieChartCustom from "../../components/Chart/PieChart";
import { apiPost } from "../../data/apidata";
import { processCallsList } from "../../components/charts/JourneyFunctions";
import { useEffect } from "react";
import BarChartCustom from "../../components/Chart/BarChart";
import { getColour, getDaysArray, groupBy } from "../../functions/objects_arrays";
import { Card } from "@mui/material";
import VerticalHistogram from "../../components/Chart/VerticalHistoram";
import AQDateRangePicker from "../../components/Chart/DateRangePicker/DateRangePicker";
import { useUserSettings } from "../../components/UserSettingsProvider/UserSettingsProvider";
import { secondsToHHMMSS } from "../../functions/dataHandler";
import AverageResponseChart from "../../components/AverageResponseChart/AverageResponseChart";
import TotalCallsChart from "../../components/TotalCalls/TotalCalls";
import GridLayout from "react-grid-layout";
import { Bar } from "react-chartjs-2";

export default function Insights() {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  sevenDaysAgo.setHours(0, 0, 0, 0); // Set to the start of the day

  const today = new Date();
  today.setHours(23, 59, 59, 999); 

  const [value, setValue] = useState({
    from: sevenDaysAgo.toISOString(),
    to: today.toISOString(),
  });
  
  const { userSettings, updateSettings } = useUserSettings();
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [error, setError] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [firstLoad, setfirstLoad] = useState(true);
    
    function getData(){
      
      const apiParams = { 
        "siteID" : `${userSettings.site.id}`,
        "start": `${value.from}`,
        "end": `${value.to}`
      };

      setfirstLoad(false);
      if ((apiParams==undefined)||(apiParams==null)||(apiParams=="")) return;
        if (apiParams==undefined) return;
        setData([]);
        apiPost(
            "/api/messages/reports/list",
            apiParams,
            (d)=>{
                let r = d["message"]["data"][0];
                setRawData(r);
                r = processCallsList(r);
                setData(r);
            },
            (e)=>{
              console.log(e)
              setData([]);
            }
        );
    }
    
  function updateSearchVal(e){
    setSearchVal(e.target.value);
  }

  function updateDates(start, end){
    setValue({
      from: start,
      to: end
    });
  }

  useEffect(()=>getData(),[firstLoad===true]);
  useEffect(()=>getData(),[value.from, value.to])


  const layout = [
    { i: "totalcalls", x: 0, y: 5, w: 12, h: 1, static: true },
    { i: "AverageResponseChart", x: 0, y: 6, w: 3, h: 2, static: true  },
/*    { i: "ProReactPie", x: 0, y: 6, w: 6, h: 2, static: true },
    { i: "ProReactPie", x: 6, y: 6, w: 6, h: 2, static: true },
    { i: "ProReactPie", x: 4, y: 0, w: 1, h: 2, static: true }
*/
    ];
/*
  return(
    <GridLayout
    className="layout"
    layout={layout}
    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    rowHeight={30}
    width={1200}
  >
    <div key="totalcalls">
      <ShowCard className="card_width_full" title="Total Calls">
        {data!==null&&<TotalCallsChart calls={data} start={value.from} end={value.to} />}
      </ShowCard>
    </div>

    <div key="AverageResponseChart">
      <ShowCard className="card_width_full" title="">
        <AverageResponseChart data={data} />
      </ShowCard>    
    </div>
  {/*}
    <div key="ProReactPie">
      <ShowCard className="card_width_full" title="Proactive v Reactive"><ProReactPie data={data} /></ShowCard>
    </div>

    <div key="b"></div>

    <div key="b"></div>

    <div key="b"></div>

    <div key="b"></div>

    <div key="b"></div>

    <div key="b"></div>
  
  </GridLayout>

  );
    */

  
  return (

    <main className="px-12 py-12 dashboard_layout">

      <div>
        <AQDateRangePicker start={value.from} end={value.to} onChange={updateDates}/>
      </div>
      
      <br />

      <div className="cards_list">
        <TotalCalls data={data} />
        <BusiestTimeOfDay data={data} />
        <AverageResponseTime data={data} />
        <BusiestDay data={data} />
        {/*<BusiestDay data={data} />*/}
      </div>
      
      <br />
      
      <div className="two_column_split">
        <ShowCard className="card_width_half" title="All Calls"><AllCallsPie data={rawData} /></ShowCard>
        <ShowCard className="card_width_half" title="Calls by Origin"><AllCallsPie data={data} /></ShowCard>
      </div>
      
      <br />
      
      <div className="two_column_split">
        <ShowCard className="card_width_half" title="Calls by Day"><CallsByDay data={data} start={value.from} end={value.to} /></ShowCard>
        <ShowCard className="card_width_half" title="Emergency V Non Emergency"><EmergencyNon data={data} /></ShowCard>
      </div>
     
      
      <br />
      <div className="two_column_split">
        <ShowCardRed className="card_width_half" title="">
          <AverageResponseChart data={data} />     
        </ShowCardRed>
        <ShowCard className="card_width_half" title="Calls by Room">
          <CallsByRoom data={data} start={value.from} end={value.to} />
        </ShowCard>
      </div>
      
      <br />

      <div className="two_column_split">
        <ShowCard className="card_width_half" title="Calls by Month"><CallsByMonth data={data} /></ShowCard>
        <ShowCard className="card_width_half" title="Calls by Zone"><CallsByZone data={data} /></ShowCard>
      </div>

      <div className="two_column_split">
        <ShowCard className="card_width_medium" title="Proactive v Reactive"><ProReactPie data={data} /></ShowCard>
      </div>

    </main>
    
);


}

function flattenJourneyArray(arr) {
  if (arr===null) return [];
  const rtn = [];

  Object.values(arr).forEach(d=>{
    rtn.concat(d.calls);
  });

  return rtn;
}

const BusiestTimeOfDay=({data})=>{
  
  const [chartData, setChartData] = useState({
    title: "Busiest Time of Day",
    metric: "-",
  });
  

  useEffect(()=>{
    setChartData(null);
    const busy = groupAndFindBusiestHour(data);
    if (busy===undefined) return;
    
    const time = busy.busiestHour;
    let display = (time!==null) ?`${(time<12)?time:(time-12)}${(time<12)?"am":"pm"}` : "";
    if (display==="0pm") { display = "-"}
    const newData = { title: "Busiest Time of Day", metric: display };

    setChartData(newData);
    
  }, [data])

  if (chartData===null) return <></>;

  return <DashboardCard item={chartData} />;
}


const   TotalCalls=({data})=>{
  
  const [chartData, setChartData] = useState({
    title: "Total Calls",
    metric: "-",
  });
  

  useEffect(()=>{
    const newData = { title: "Total Calls", metric: (Array.isArray(data)===false) ? "0" : data.length };
    setChartData(newData);
  }, [data])

  if (chartData===null) return <></>;

  return <DashboardCard item={chartData} />;
}

const BusiestDay=({data})=>{
  
  const [chartData, setChartData] = useState({
    title: "Busiest Time of Day",
    metric: "-",
  });
  

  useEffect(()=>{
    const busy = groupAndFindBusiestDay(data);
    if (busy===undefined) return;
    
    const time = busy.busiestDay;
    const newData = { title: "Busiest Day", metric: time };

    setChartData(newData);
  }, [data])

  if (chartData===null) return <></>;

  return <DashboardCard item={chartData} />;
}

export function calculateAverageDuration(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return 0; // or you can return null, NaN, or handle it based on your use case
  }

  const totalDuration = data.reduce((sum, item) => sum + (item.durationSeconds || 0), 0);
  const averageDuration = totalDuration / data.length;

  // Round up to the nearest whole number
  const roundedAverage = Math.ceil(averageDuration);

  return roundedAverage;
}

const AverageResponseTime=({data})=>{
  
  const [chartData, setChartData] = useState({
    title: "Average Response Time",
    metric: "-",
  });
  
  useEffect(()=>{
    const dur = calculateAverageDuration(data);
    if (dur===undefined) return;
    
    const newData = { title: "Average Response Time", metric: secondsToHHMMSS(dur) };

    setChartData(newData);
  }, [data])

  if (chartData===null) return <></>;

  return <DashboardCard item={chartData} />;
}

const ShowCard=(props)=>{

  return(
    <div className={`${(props.noPadding)? "card_template_no_padding":"card_template"} card_layout ${props.className}`}>
      {(props.title!=="")&&<h4>{props.title}</h4>}
      {props.children}
    </div>
  );
}

const ShowCardRed=(props)=>{
  return(
    <div 
    className={`card_template_no_padding card_layout ${props.className}`}
    >
      {(props.title!=="")&&<h4>{props.title}</h4>}
      {props.children}
    </div>
  );
}

const ProReactPie=({data})=>{

  const [pieData, setPieData] = useState(null);

  useEffect(()=>{
    if (data===null) return;
    const tmp = groupBy(data, "proreact");

    setPieData(
        Object.keys(tmp).map(k=>{
            return {
                id: k,
                value: tmp[k].length, 
                label: k,
                color: getCallColours(k)
            }
        })
    )
    
  }, [data])
  
  return <PieChartCustom data={pieData} />;

}

const CallsByRoom=({data, start, end})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;    const groups = groupBy(data, "callType");
    const datesArr = Object.keys(groupBy(data, "room"));//getDaysArray(start, end);
    
    let dat = {
        labels: datesArr,
        datasets: getGroupedDatasetByKey(groups, "room")
    };

    console.log("valuesvalues", dat);
    setChartKeys(dat);
    setChartData(dat);
    
  }, [data])
  
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    options: {
      scales: {
          y: {
              ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value, index, ticks) {
                      return '$' + value;
                  }
              }
          }
      }
  },

  };


  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <Bar data={chartData} keys={chartKeys} options={options} />;

}


const CallsByMonth=({data, start, end})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;    const groups = groupBy(data, "callType");
    const datesArr = Object.keys(groupBy(data, "StartMonth")).map(k=>getMonthNameFromNumber(k ));//getDaysArray(start, end);
    
    let dat = {
        labels: datesArr,
        datasets: getGroupedDatasetByKey(groups, "room")
    };

    console.log("valuesvalues", dat);
    setChartKeys(dat);
    setChartData(dat);
    
  }, [data])
  
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        stacked: true,
         
          callback: function(value, index, ticks, p) {
            console.log("Tick value:", value); // Debugging line
            console.log("index value:", index); // Debugging line
            console.log("ticks value:", ticks); // Debugging line
            console.log("p value:", value); // Debugging line
            
            return getMonthNameFromNumber(value);
          }
        
      },
      y: {
        stacked: true,
      },
    },
  };


  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <Bar data={chartData} keys={chartKeys} options={options} />;

}

const CallsByZone=({data, start, end})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;    const groups = groupBy(data, "callType");
    const datesArr = Object.keys(groupBy(data, "zone"));
    
    let dat = {
        labels: datesArr,
        datasets: getGroupedDatasetByKey(groups, "room")
    };

    console.log("valuesvalues", dat);
    setChartKeys(dat);
    setChartData(dat);
    
  }, [data])
  
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };


  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <Bar data={chartData} keys={chartKeys} options={options} />;

}


const CallsByZones=({data})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;
    const tmp = groupBy(data, "zone");
    const values = Object.values(tmp).map(v=>v.length);
    const keys = Object.keys(tmp).map(v=>v);

    setChartKeys(keys);
    setChartData(values);
    
    
  }, [data])
  
  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <VerticalHistogram data={chartData} keys={chartKeys} />;

}


function getMonthNameFromNumber(monthNumber) {
  console.log("monthsNum", monthNumber);
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Check if the provided month number is valid
  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthNames[monthNumber - 1]; // Adjust index (1-based to 0-based)
  } else {
    return 'Invalid Month Number';
  }
}

/*
  
  const CallsByDay=({data})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;
    const tmp = groupBy(data, "StartDate");
    const values = Object.values(tmp).map(v=>v.length);
    const keys = Object.keys(tmp);

    setChartKeys(keys);
    setChartData(values);
    
  }, [data])
  
  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <VerticalHistogram data={chartData} keys={chartKeys} />;

}

*/

function getGroupedDataset(groupedCalls, datesArr){
  const rtnObject = Object.keys(groupedCalls).map((k=>{
    return {
      label: k,
      data: sumDurationByDate(groupedCalls[k], datesArr),
      backgroundColor: getColour(k)            
    };
  }))
  return rtnObject;
}

function getGroupedDatasetByKey(groupedCalls, key){
  const rtnObject = Object.keys(groupedCalls).map((k=>{
    return {
      label: k,
      data: Object.values(groupBy(groupedCalls[k], key)).map(d=>d.length),
      backgroundColor: getColour(k)            
    };
  }))
  return rtnObject;
}

function sumDurationByDate(objects, dates) {
  // Create an object to store the sum of durationSeconds for each date
  const dateDurations = {};

  // Initialize dateDurations with zero for each date in the dates array
  dates.forEach(date => {
    dateDurations[date] = 0;
  });

  // Sum the durationSeconds for each date
  objects.forEach(obj => {
    const objDate = obj.StartDate;
    if (dateDurations.hasOwnProperty(objDate)) {
      dateDurations[objDate] += 1;
    }
  });

  // Create the data array from dateDurations
  const data = dates.map(date => dateDurations[date]);

  // Return the final object
  return data;
}

const CallsByDay=({data, start, end})=>{

  const [chartData, setChartData] = useState(null);
  const [chartKeys, setChartKeys] = useState(null);

  useEffect(()=>{
    
    if (data===null) return;
    console.log("dataa", data);
    const tmp = groupBy(data, "StartDate");
    const values = Object.values(tmp).map(v=>v.length);
    const keys = Object.keys(tmp);

    const groups = groupBy(data, "callType");
    const datesArr = getDaysArray(start, end);
    
    let dat = {
        labels: datesArr,
        datasets: getGroupedDataset(groups, datesArr)
    };

    console.log("valuesvalues", dat);
    setChartKeys(dat);
    setChartData(dat);
    
  }, [data])
  
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };


  if ((chartData===null)||(chartKeys===null)) return <></>;
  return <Bar data={chartData} keys={chartKeys} options={options} />;

}


const AllCallsPie=({data})=>{

  const [pieData, setPieData] = useState(null);

  useEffect(()=>{
    if (data===null) return;
    const tmp = groupBy(data, "callType");
    setPieData(
        Object.keys(tmp).map(k=>{
            return {
                id: k,
                value: tmp[k].length, 
                label: k,
                color: getCallColours(k)
            }
        })
    )
    
  }, [data])
  
  return <PieChartCustom data={pieData} />;

}


const EmergencyNon=({data})=>{

  const [pieData, setPieData] = useState(null);

  useEffect(()=>{
    if (data===null) return;
    const tmp = groupBy(data, "EmergencyNon");
    setPieData(
        Object.keys(tmp).map(k=>{
            return {
                id: k,
                value: tmp[k].length, 
                label: k,
                color: getCallColours(k)
            }
        })
    )
    
  }, [data])
  
  return <PieChartCustom data={pieData} />;

}

function getCallColours(callType){

  try { callType = callType.toLowerCase(); } catch(e) {
    //return "#48C9B0";
    return "#4cc1bd";}
  if (callType==="emergency") return "red";
  if (callType==="call") return "rgb(245, 129, 78)";
  if (callType==="sense") return "#914397";
  if (callType==="attendance") return "rgb(148, 202, 102)";
  if (callType==="accessory") return "#914397";
  if (callType==="assistance") return "#F8DA3C";
  if (callType==="carecall") return "rgb(225, 21, 131)";
  if (callType==="call") return "orange";
  if (callType==="visit") return "rgb(1, 87, 62)";
  if (callType==="proactive") return "#5DADE2";
  if (callType==="reactive") return "#F5B041";
  //return "#48C9B0";
  return "#4cc1bd";
}


function displayDateFromISO(dt){
  dt = dt.toUTCString();
  //dt = dt.split(", ")[1];
  dt = dt.split(" ");
  return `${dt[1]} ${dt[2]} ${dt[3]}`;
}

function groupAndFindBusiestDay(data) {
  if (!data || data.length === 0) return;

  // Create an object to store groups based on the day
  const groupedData = {};

  // Iterate through the array
  data.forEach(entry => {
    // Extract the day from the 'start' field
    const startDate = new Date(entry.start);
    const startDay = displayDateFromISO(startDate);//startDate.toISOString().split('T')[0]; // Extracting the day portion

    // If the day group doesn't exist, create it
    if (!groupedData[startDay]) {
      groupedData[startDay] = [];
    }

    // Push the entry to the corresponding day group
    groupedData[startDay].push(entry);
  });

  // Find the busiest day
  let busiestDay = null;
  let maxEntries = 0;

  for (const day in groupedData) {
    if (groupedData[day].length > maxEntries) {
      maxEntries = groupedData[day].length;
      busiestDay = day;
    }
  }

  return {
    groupedData,
    busiestDay,
    busiestDayEntries: groupedData[busiestDay],
  };
}

function groupAndFindBusiestHour(data) {
  if (data===null) return;
  if (data===undefined) return;
  // Create an object to store groups based on the hour
  const groupedData = {};

  // Iterate through the array
  data.forEach(entry => {
    // Extract the hour from the 'start' field
    const startHour = new Date(entry.start).getHours();

    // If the hour group doesn't exist, create it
    if (!groupedData[startHour]) {
      groupedData[startHour] = [];
    }

    // Push the entry to the corresponding hour group
    groupedData[startHour].push(entry);
  });

  // Find the busiest hour
  let busiestHour = null;
  let maxEntries = 0;

  for (const hour in groupedData) {
    if (groupedData[hour].length > maxEntries) {
      maxEntries = groupedData[hour].length;
      busiestHour = hour;
    }
  }

  return {
    groupedData,
    busiestHour: busiestHour,
    busiestHourEntries: groupedData[busiestHour],
  };
}


