import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { apiPost } from '../../../data/apidata';

const DeleteGroup = ({onClose, data, siteID})=>{
    const { enqueueSnackbar } = useSnackbar();
    console.log('delete', data.deviceGroupID)

    const handleSubmit=(e)=>{
        e.preventDefault();
        apiPost(
            'api/messages/site/devicegroups/delete',    
            {
                'deviceGroupID': data.deviceGroupID
            },
            (d)=>{
                console.log('group_delete',d.message)
                enqueueSnackbar("Group deleted", {variant:"success"});
                onClose()
                },
            (e)=>{console.log('groupAdd_err',e)}
        )
    

    }


    return(
        <div div className='inner_layer' style={{ width: '500px', paddingLeft:"30px", paddingRight:"30px"}}>
            <form className='aqForm' style={{height: '180px'}} onSubmit={handleSubmit}>
            <h3>Delete Group</h3>
            <div style={{fontSize:'17px', color: '#6B7280'}}>
                Are you sure you want to delete this group?<br/>
                This action cannot be undone.
            </div>
            <div className='submit_fields'>
                <div className='validate_close_div'>
                    <button className='clear_button' type='submit' style={{width: '70px'}}>Delete</button>
                    <button className='close_buton' onClick={onClose} style={{width: '70px'}}>Cancel</button>
                </div>
            </div>
            </form>
        </div>
    );
}

export default DeleteGroup