import { useState, useEffect } from "react";
import { apiPost } from "../../../data/apidata";
import { columns_template_integrations } from "../../../components/AQDataTable/columns_templates";
import {Card} from "@tremor/react";
import AQDataTable from "../../../components/AQDataTable/AQDataTable";
import TableTemplateAPI from "../UserManagement/TableTemplateAPI";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";


export const Integrations=()=>{

    const { userSettings, updateSettings } = useUserSettings();
    const [firstLoad, setFirstLoad] = useState(true);
    const [configData, setConfigData] = useState([])
    
    function getDBCode() {
        try {
            return userSettings.site.id;
        }
        catch (e) { return "" }
    }

    const postData = {
        'siteID' : userSettings.site.id
    }

    function getNotifications(){
        const siteID = getDBCode();
        if (firstLoad===true) setFirstLoad(false);
        if (siteID==="") return;      
    

        apiPost("api/messages/integrations/site_config", 
            {"siteID": siteID},
            (d)=>{
                console.log(d.message)
                const tempData = d.message.map(item =>{

                    let transformedStatus;

                    // Conditional logic based on item.status
                    if (item.integrationEnabled === 1) {
                      transformedStatus = 'Active';
                    } else if (item.integrationEnabled === 0) {
                      transformedStatus = 'Inactive';
                    } else {
                      transformedStatus = 'Unknown';
                    }

                    return {
                        integrationEnabled: transformedStatus,
                        integrationKey: item.integrationKey,
                        integrationType: item.integrationType,
                        siteID: item.siteID
                    }
                })
                setConfigData(tempData)
            },
            (e)=>{
                console.log('config_err',e)
                const tempData ={
                    integrationEnabled: 'error',
                    integrationKey: 'error',
                    integrationType: 'error',
                    siteID: siteID
                }
                setConfigData(tempData)
            }
        )
    }

    useEffect(() => {
        const intervalId = setInterval(getNotifications, 60*1000);
    
        return () => clearInterval(intervalId);
      }, [firstLoad]);


    useEffect(() => { getNotifications(); }, [firstLoad === true]);

    return(
        <TableTemplateAPI
            siteID={userSettings.site.id}
            title={"Integrations"}
            apiPath={"api/messages/integrations/site_config"}
            apiParams={postData}
            tableTemplate={columns_template_integrations}
            tableSettingsType={"settings_integrations"}
            onUpdateRefreshPath={""}
        />

    );
}

export default Integrations;