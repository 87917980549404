import { Card, Title, LineChart } from "@tremor/react";
import { useState, useEffect } from "react";
import { getOnValue } from "../../data/firebase";
import { getDifferenceInSeconds, getMinMax, orderArrayByDate, splitTXCode } from "../../functions/dataHandler";
import { useModal } from "../ModalContext/ModalContext";
import { DataModal } from "./DataModal";
import "./LiveDashboard.css";
import { JourneyCard } from "./JourneyCard";
import { processCallsListForLiveData, getAverageDuration, cleanCallTypeText } from "./JourneyFunctions";
import { CallTile } from "./CallTile/CallTile";
import { CallTileContainer } from "./CallTileContainer/CallTileContainer";
import { Grid } from '@mui/material';

export default function LiveDashboardToday({ siteCode }) {

  const [data, setData] = useState([]);
  const callsList = ["Emergency", "Sense", "Assistance", "Call", "Attendance", "CareCall"];
  const [error, setError] = useState({});
  const [allCallData, setAllCallData] = useState([]);
  const [journeyData, setJourneyData] = useState([]);
  const { isModalOpen, openModal, closeModal } = useModal();
  const [selectedData, setSelectedData] = useState(null);


  useEffect(() => {
    if (selectedData === null) {
      closeModal();
    }
    else {
      openModal();
    }
  }, [selectedData]);


  useEffect(() => {
    setAllCallData(data.filter(d => (d.aqRef !== undefined)));
  }, [data]);

  useEffect(() => {
    //setJourneyData(allCallData);
    setJourneyData(processCallsListForLiveData(allCallData));
  }, [allCallData]);

  useEffect(() => {
    if ((siteCode === undefined) || (siteCode === null) || (siteCode === "")) return;

    const dbPath = siteCode + "_site/calls/" + getDatePathFromDate(new Date());
    getOnValue(dbPath,
      (d) => {
        try {
          setData(Object.values(d));
          console.log("Calls from firebase", (Object.values(d).map(o => { if (o.callType === "Sense") return o })))
        }
        catch (e) {
          console.log("Error setting records");
          setData([]);
        }
      }, setError);

  }, [siteCode])


  function doubleFigure(val) {
    if (val < 10) return "0" + val;
    return val;
  }

  function getDatePathFromDate(d) {
    return `${doubleFigure(d.getDate())}${doubleFigure(d.getMonth() + 1)}${doubleFigure(d.getFullYear())}`;
  }

  function getCallData(key) {
    if (key === "All Calls") return [...journeyData];
    let rtn = journeyData.filter(j => j.callType === key);
    if (rtn === undefined) rtn = [];
    return rtn;
  }

  function translateCallTypes(ct) {
    if (ct === "Sense") return "Sense";
    return ct;
  }

  return (
    <>
      {(isModalOpen && (selectedData !== null))
        && (
          <DataModal
            title={(selectedData.title) ? selectedData.title : ""}
            selectedData={(selectedData.data) ? selectedData.data : null}
            closeModal={closeModal}
          />
        )}

      <CallTileContainer title="Today's activity">



        <Grid container spacing={1}>
          <Grid item lg={4} md={6} xs={12} sm={12}>
            <CallTile
              title="All Calls"
              data={getCallData("All Calls")}
              className="all"
              allCallData={getCallData("All Calls")}
              setSelectedData={setSelectedData}
            />
          </Grid>
          {
            callsList.map((c) =>
              <Grid item lg={4} md={6} xs={12} sm={12}>
                <CallTile
                  title={c}
                  data={getCallData(translateCallTypes(c))}
                  className={cleanCallTypeText(c)}
                  setSelectedData={setSelectedData}
                />
              </Grid>
            )
          }

        </Grid>
      </CallTileContainer>

    </>
  )

  return (
    <Card
      className="height-full"
    >

      {isModalOpen && (
        <DataModal title={(selectedData.title) ? selectedData.title : ""} selectedData={(selectedData.data) ? selectedData.data : null} closeModal={closeModal} />
      )}

      <Title className="dashboard_section_title upper_case">Today's activity</Title>

      <div className="todays_activity_container">
        <CallTile
          title="All Calls"
          data={getCallData("All Calls")}
          className="all"
          allCallData={getCallData("All Calls")}
          setSelectedData={setSelectedData}
        />

        {
          callsList.map((c) => <CallTile
            title={c}
            data={getCallData(translateCallTypes(c))}
            className={cleanCallTypeText(c)}
            setSelectedData={setSelectedData}
          />
          )
        }

      </div>


    </Card>
  )

};